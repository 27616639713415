import React, { forwardRef } from "react"
import styled from "styled-components"
import { theme } from "../../../theme"
import {
  ConfigProvider,
  RefSelectProps,
  Select as SelectComp,
  SelectProps,
} from "antd"

import jaJP from "antd/lib/locale/ja_JP"
import i18next, { t } from "i18next"
import { LoadingOutlined } from "@ant-design/icons"
import {
  handleScrollBodyBehavior,
  handleScrollTableBehavior,
} from "../../../utils"
interface ISelect extends SelectProps {
  name?: any
  value?: any
  error?: any
  style?: any
  open?: boolean
  onChange?: any
  label?: string
  right?: number
  height?: number
  clear?: boolean
  options?: any[]
  padding?: string
  [x: string]: any
  fontsize?: string
  loading?: boolean
  className?: string
  required?: boolean
  disabled?: boolean
  onClick?: () => void
  placeholder?: string
  showSearch?: boolean
  bordercolor?: string
  onSearch?: () => void
  popupClassName?: string
  mode?: "multiple" | "tags"
  notFoundContent?: React.ReactNode
  dropdownMatchSelectWidth?: boolean
  defaultValue?: string | string[] | number | number[]
  getPopupContainer?: (val?: any) => HTMLElement
  /**
   * [NOTE:] Bug: Datepicker popup hides behind table when inside a cell.
   * Fix: Render popup within ant-table-content; use anchorParentElement for non-scrollable datepicker.
   */
  scrollPreventEl?: HTMLElement
}

interface props {
  width?: number
  height?: number
  fontsize?: string
  bordercolor?: string
  value?: string
  padding?: string
  right?: number
}

const SelectWrapper = styled.div`
  display: flex;
  .ant-select-selector {
    overflow: hidden;
  }
  width: 100%;
`
const Wrapper = styled.div`
  .ant-select {
    width: ${(props) => (props?.width ? `${props?.width}px` : "100%")};
  }
  &.input-assessment-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-select-arrow {
    right: ${(props) =>
      props?.right ? `${props?.right}px !important` : "5px !important"};
  }
  .ant-select-arrow > span {
    line-height: 0px !important;
  }
  .ant-select-selector {
    height: ${({ height }: props) =>
      height ? height + "px !important" : "30px !important"};
    font-size: ${({ fontsize }: props) => fontsize || "13px"};
    border-radius: 6px !important;
    align-items: center;
    .ant-select-selection-overflow-item {
      padding-right: 1px;
      align-self: start;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .ant-select-selection-item {
      padding: 0;
      height: 16px;
      line-height: ${({ height }: props) =>
        height ? `${height}px !important` : `14px`};
      margin-right: 10px;
    }
    .ant-select-selection-placeholder {
      display: flex;
      color: #808080;
    }
    @media (max-width: 480px) {
      height: 41px;
      font-size: 17px;
      .ant-select-selection-item {
        height: 28px;
        line-height: 28px;
      }
      .ant-select-selection-overflow {
        margin-top: -5px;
      }
    }
  }
  @media (max-width: 480px) {
    .ant-select-arrow {
      display: inline-block;
      right: 5px !important;
    }
    .ant-select-clear {
      opacity: ${({ value }: props) => (value && "1") || "0"};
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: static;
    padding: ${({ padding }: props) => (padding ? padding : `0 10px`)};
  }
  .ant-select-single {
    .ant-select-selection-item {
      height: auto;
      line-height: 30px;
    }
    .ant-select-selection-placeholder {
      line-height: ${({ height }: props) =>
        height ? `${height}px !important` : ``};
    }
  }
  .ant-select-multiple .ant-select-selection-item-content {
    white-space: normal;
  }
  .left-align {
    text-align: left !important;
  }
`

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
`
const Label = styled.div`
  ${theme.typography.PackDesc};
  font-weight: 700;
  font-size: 15px;
  width: 210px;
`
const RequiredLabel = styled.div`
  ${theme.typography.PackDesc};
  line-height: 20px;
  color: #f5222d;
  min-width: 40px;
  display: flex;
  justify-content: center;
  height: 22px;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
`

const Error = styled.div`
  color: ${theme.alert};
  font-size: 12px;
`

// [NOTE]: Changed the suffix icon of select input to custom icon
// as per figma design.
const SelectArrowDownIcon = () => {
  return (
    <svg
      width={"10"}
      height={"5"}
      viewBox={"0 0 10 5"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={"M9.21094 1L5.12075 3.85999L0.999969 1"}
        stroke={"#191919"}
        strokeWidth={"1.2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export const SelectInput = forwardRef<RefSelectProps, ISelect>((props, ref) => {
  const {
    mode,
    name,
    open,
    value,
    width,
    clear,
    label,
    error,
    right,
    height,
    onBlur,
    options,
    onClick,
    loading,
    padding,
    onChange,
    required,
    onSearch,
    disabled,
    className,
    showSearch,
    placeholder,
    bordercolor,
    defaultValue,
    popupClassName,
    notFoundContent,
    scrollPreventEl,
    dropdownMatchSelectWidth,
    getPopupContainer,
    id = "",
  } = props
  const [openSelector, setOpenSelector] = React.useState<boolean>(open || false)

  React.useEffect(() => {
    handleScrollBodyBehavior(openSelector)
    if (scrollPreventEl) {
      handleScrollTableBehavior(openSelector, scrollPreventEl)
    }
  }, [openSelector])
  return (
    <ConfigProvider
      locale={i18next.language === "ja" && jaJP}
      renderEmpty={() => (
        <div>
          <svg
            className={"ant-empty-img-simple"}
            width={"64"}
            height={"41"}
            viewBox={"0 0 64 41"}
            xmlns={"http://www.w3.org/2000/svg"}
          >
            <g transform={"translate(0 1)"} fill={"none"} fillRule={"evenodd"}>
              <ellipse
                className={"ant-empty-img-simple-ellipse"}
                cx={"32"}
                cy={"33"}
                rx={"32"}
                ry={"7"}
              ></ellipse>
              <g className={"ant-empty-img-simple-g"} fillRule={"nonzero"}>
                <path
                  d={
                    "M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                  }
                ></path>
                <path
                  d={
                    "M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  }
                  className={"ant-empty-img-simple-path"}
                ></path>
              </g>
            </g>
          </svg>
          <p>{t("No data")}</p>{" "}
        </div>
      )}
    >
      <Wrapper
        width={width}
        height={height}
        value={value}
        bordercolor={error ? theme.red2 : bordercolor}
        padding={padding}
        className={className}
        right={right}
        id={id}
      >
        <SelectWrapper id={"select_input_area"}>
          {label && (
            <LabelContainer>
              <Label>{label}</Label>
              {required && <RequiredLabel>{"必須"}</RequiredLabel>}
            </LabelContainer>
          )}
          {showSearch ? (
            <SelectComp
              ref={ref}
              allowClear={clear}
              defaultValue={defaultValue}
              options={options}
              className={`${className} ${error && "error"}`}
              value={value || undefined}
              id={name}
              fieldNames={name}
              placeholder={placeholder}
              onClick={onClick}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e)
                }
                setOpenSelector(false)
              }}
              onChange={(e) => {
                onChange(e)
                setOpenSelector(false)
              }}
              onFocus={() => {
                setOpenSelector(true)
              }}
              showArrow
              onSearch={onSearch}
              loading={loading}
              open={openSelector}
              dropdownMatchSelectWidth={dropdownMatchSelectWidth}
              showSearch={showSearch}
              suffixIcon={
                loading ? <LoadingOutlined /> : <SelectArrowDownIcon />
              }
              filterOption={(input, option) =>
                option?.label
                  ?.toString()
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
              mode={mode}
              disabled={disabled || false}
              popupClassName={popupClassName}
              getPopupContainer={getPopupContainer}
            />
          ) : (
            <SelectComp
              ref={ref}
              allowClear={clear}
              defaultValue={defaultValue}
              options={options}
              className={`${className} ${error && "error"}`}
              value={value || undefined}
              showArrow
              id={name}
              fieldNames={name}
              placeholder={placeholder}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e)
                }
                setOpenSelector(false)
              }}
              onChange={(e) => {
                onChange(e)
                setOpenSelector(false)
              }}
              onFocus={() => {
                setOpenSelector(true)
              }}
              onDropdownVisibleChange={(open) => {
                setOpenSelector(open)
              }}
              loading={loading}
              open={openSelector}
              dropdownMatchSelectWidth={dropdownMatchSelectWidth || false}
              mode={mode}
              suffixIcon={
                loading ? <LoadingOutlined /> : <SelectArrowDownIcon />
              }
              disabled={disabled || false}
              notFoundContent={notFoundContent}
              popupClassName={popupClassName}
              getPopupContainer={getPopupContainer}
            />
          )}
        </SelectWrapper>
        {error && <Error>{error}</Error>}
      </Wrapper>
    </ConfigProvider>
  )
})
