/* stylelint-disable no-descending-specificity */
import { Card } from "antd"
import React from "react"
import styled from "styled-components"
import { theme } from "../../../theme"

interface GridProps {
  className?: string
  background?: boolean | string
  children?: React.ReactNode
  padding?: string
  alignItems?: string
  width?: string
  borderRight?: string
  borderLeft?: string
}

const StyledGrid = styled(Card.Grid)`
  position: relative;
  border: 1px solid #d2d1d1;
  width: ${({ width }: GridProps) => {
    return width
  }};

  @media not print (max-width: 767px) {
    border-top: 0.5px solid #d2d1d1 !important;
  }
  @media (max-width: 376px) {
    border-right: ${({ borderRight }: GridProps) => {
      return borderRight
    }};
    border-left: ${({ borderLeft }: GridProps) => {
      return borderLeft
    }};
  }
  box-shadow: none;
  padding: ${({ padding }: GridProps) => {
    return padding ?? "14px 20px"
  }};

  &.card-wrapper {
    border-radius: 12px;
  }

  .recorder_field-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  &.no-padding {
    padding: 0 !important;
  }
  &.flex-full {
    flex: 1 0 auto;
  }
  &.word-break-class {
    @media (max-width: 767px) {
      word-break: break-all;
    }
  }
  &.flex-break {
    flex-basis: 100%;
    border: 0;
    padding: 0;
    + .ant-card-grid {
      border-left: 1px solid #d2d1d1;
    }
    ~ .ant-card-grid:not(.bordered) {
      border-top: 0;
    }
    @media (max-width: 767px) {
      flex: 1;
    }
  }
  .grid-content {
    display: flex;
    align-items: ${({ alignItems }: GridProps) => {
      return alignItems ?? "center"
    }};
    height: 100%;
    & .inner-grid-content {
      width: 100%;
    }
  }
  + .ant-card-grid {
    border-left: 0;
  }
  .password-show-hide {
    position: absolute;
    right: 50px;
    top: 25%;
    cursor: pointer;
    @media (max-width: 767px) {
      top: 20px;
      right: 15px;
    }
  }
  .eye-icon {
    transform: scale(1.3);
  }

  .label-with-tooltip-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &.auto-scroll {
    overflow-x: auto;
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    padding: ${({ padding }: GridProps) => {
      return padding ?? "7px 10px 10px"
    }};
    &.grid-header {
      :first-child {
        border-bottom: 0;
      }
      ~ div:not(.grid-header) {
        padding-left: 0;
        padding-right: 0;
        border-right: 0;
      }

      &.field-label-container {
        border-top: 1px solid ${theme.gray2} !important;
      }
    }

    &.grid-row-last {
      padding-bottom: 20px;
      @media (max-width: ${theme.breakpoints.md}) {
        border-bottom: 0;
      }
    }
    &.last-table-grid {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  .flex-class {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  &.no-border-mob {
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      border: none !important;
      padding: 0px;
    }
  }
  .meeting-participants {
    margin-top: 10px;
  }
`
const Grid: React.FC<GridProps> = ({
  className,
  background,
  children,
  padding,
  alignItems,
  width,
  borderRight,
  borderLeft,
}) => (
  <StyledGrid
    className={className}
    style={{
      background: background
        ? background === true
          ? "#f3f3f3"
          : background
        : "#fff",
    }}
    hoverable={false}
    padding={padding}
    alignItems={alignItems}
    width={width}
    borderRight={borderRight}
    borderLeft={borderLeft}
  >
    <div className={"grid-content"}>
      <div className={"inner-grid-content"}>{children || null}</div>
    </div>
  </StyledGrid>
)

export { Grid }
