import React from "react"
import { Tooltip } from "antd"
import styled from "styled-components"

type SharedTooltipProps = {
  title: string | React.ReactNode
}

const TooltipContainer = styled.div`
  display: flex;
  width: 389px;
`

const TooltipWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`

const TooltipText = styled.p`
  color: #191919;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  line-height: 20.27px;
`

const TooltipIcon = styled.img`
  margin-top: 4px;
`

export const SharedTooltip: React.FC<SharedTooltipProps> = (props) => {
  const { title } = props

  return (
    <Tooltip
      placement={"bottom"}
      title={
        <TooltipContainer>
          <TooltipWrapper>
            <TooltipIcon
              src={"/assets/icons/question-mark.svg"}
              alt={"tooltip"}
            />
            <TooltipText>{title}</TooltipText>
          </TooltipWrapper>
        </TooltipContainer>
      }
      overlayInnerStyle={{
        background: "white",
        padding: "1rem 2rem",
        width: "fit-content",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
      color={"#ffffff"}
    >
      <img
        src={"/assets/icons/question-mark.svg"}
        alt={"tooltip"}
        style={{ cursor: "pointer" }}
      />
    </Tooltip>
  )
}
